<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i>Add/Edit Marketplace Item
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <b-row>
                    <b-col sm="3">
                      <TextBox v-model="item.name">Item Name</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.description" >Description</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.price" >Steps price</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.location">Location</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.newQty">Stock count</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group>
                        <label>Items left</label>
                        <b-form-input v-model="item.onHand" disabled></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <SelectBox :items="statuses" v-model="item.status">Status</SelectBox>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group>
                        <label>Featured item</label>
                        <b-form-checkbox v-model="item.featured"/>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group>
                        <label>Few left</label>
                        <b-form-checkbox v-model="item.fewLeft"/>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <h3>Small Image</h3>
                      <div class="profile-img-upload">
                        <img v-if="smallImage" :src="smallImage" alt="img" style="width: 150px">
                        <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                        <input type="file" style="display: none"
                               id="smallImageUpload"
                               v-on:input="onSmallImageChoose">
                        <label for="smallImageUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                        </label>
                      </div>
                    </b-col>
                    <b-col sm="4">
                      <h3>Banner Image</h3>
                      <div class="profile-img-upload">
                        <img v-if="bannerImage" :src="bannerImage" alt="img" style="width: 150px">
                        <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                        <input type="file" style="display: none"
                               id="bannerImageUpload"
                               v-on:input="onBannerImageChoose">
                        <label for="bannerImageUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                        </label>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="d-flex justify-content-end">
                    <div class="form-actions">
                      <b-button right type="submit" variant="primary" @click="onSubmit">Save</b-button>
                      <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/item/list'">Cancel</router-link>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {required} from 'vuelidate/lib/validators';

  export default {
    name: 'ItemAddEditPage',
    data () {
      return {
        item: {
        },
        smallImage: '',
        bannerImage: '',
        statuses: [{value:'ACTIVE',text:'Active'},{value:'INACTIVE',text:'Inactive'},{value:'OUT_OF_STOCK',text:'Out of stock'}]
      }
    },
    created () {
      if (!this.$route.params.id || this.$route.params.id <= 0) {
        return
      }
      this.axios.get('marketplace/item', {params: {id: this.$route.params.id}})
          .then(response => {
            this.item = response.data.data
            if (this.item.smallImage) {
              this.smallImage = this.item.smallImage.imageURL
            }
            if (this.item.bannerImage) {
              this.bannerImage = this.item.bannerImage.imageURL
            }
          })
          .catch(error => {
            if (error.response && error.response.data.status === 400) {
              this.$toastr.error(error.response.data.message)
            }
          })
    },
    methods: {
      onSubmit (event) {
        event.preventDefault()
        if (this.$v.item.name.$invalid || this.$v.item.price.$invalid) {
          this.$v.$touch()
          return
        }
        let axios
        if (this.item.id) {
          axios = this.axios.put('marketplace/item', this.item)
        } else {
          axios = this.axios.post('marketplace/item', this.item)
        }
        axios.then((res) => {
          if (res.data && res.data.status === 200) {
            this.$toastr.success(res.data.message)
            this.$router.push('/item/list')
          } else {
            this.$toastr.error(res.data.message)
          }
        }).catch((error) => {
          if (error.message) {
            this.$toastr.error(error.message)
          }
        })
      },

      onSmallImageChoose (event) {
        this.selectedFile = event.target.files[0]
        const formData = new FormData()
        formData.append('uploadFile', this.selectedFile)

        this.axios.post('public/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.smallImage = {id: response.data.data.id}
                this.smallImage = response.data.data.imageURL
              }
            })
      },
      onBannerImageChoose (event) {
        this.selectedFile = event.target.files[0]
        const formData = new FormData()
        formData.append('uploadFile', this.selectedFile)

        this.axios.post('public/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.bannerImage = {id: response.data.data.id}
                this.bannerImage = response.data.data.imageURL
              }
            })
      },
    },
    validations: {
      item: {
        name: {
          required
        },
        price: {
          required
        }
      }
    }
  };
</script>

<style scoped>

</style>